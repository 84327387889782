import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { LoginService } from './pages/home/login.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private menu: MenuController, private login: LoginService) {}
  closemenu(){this.menu.close('first');}
  closemenu2(){this.menu.close('second');}
  refresh() {location.reload();}
  cerrarsesion() {this.menu.close('second'); this.login.removedata();}
}
