/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AlertesService } from '../alertes.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  storage2: Storage | null = null;
  scanres: string;
  authState;
  ustok: any=[];
  ltodo: any=[];
  postData: any = new FormData();
  // path2 = 'https://manager.lagradaonline.com/api/api.php';
  // pathleer2 = 'https://manager.lagradaonline.com/api/leer.php';
  path2 = this.alertService.path2;
  pathleer2 = this.alertService.pathleer2;

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };
  Options3 = { headers: new HttpHeaders({'Content-Type':  'application/json', Accept: 'aplication/json'})};

  constructor(private http: HttpClient, private store: Storage, private alertService: AlertesService, private router: Router ) {
    this.init();
  }

  // tslint:disable: no-string-literal
  async init() {
    const storage = await this.store.create();
    this.storage2 = storage;
  }
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isAuthenticated() === true) {
      return true;
    } else {
      return false; }
}
getusersbd2() {
  this.postData = { accio: 'users'};
  // return this.http.post(this.path, this.postData, this.httpHeader);
  const urlget = `${this.pathleer2}?accio=users`;
  return this.http.get(urlget );
}

get_tablas_bd() {
  this.postData = { accio: 'tablas'};
  // return this.http.post(this.path, this.postData, this.httpHeader);
  const urlget = `${this.pathleer2}?accio=tablas`;
  return this.http.get(urlget);
}

get_columnas_bd(taulabd: any) {
  this.postData = {
    accio: 'columnas',
    taula: taulabd
};
  // return this.http.post(this.path, this.postData);
  const urlget = `${this.pathleer2}?accio=columnas&taula=${taulabd}`;
  return this.http.get(urlget);
}
async get_token() {
const token: any = [];
await this.store.get('sktok').then(

  data => { token['pass'] = data; },
  error => {console.error(error); this.authState = false;
            this.router.navigate(['login']); return; }
);
await this.store.get('sktokid').then(
  data => { token['iduser'] = data; },
  error => {console.error(error); this.authState = false; return; }
);
token['accio'] = 'logtok';
this.postData = {...token};
console.log(this.postData);
await this.altatok(this.postData).subscribe( data => {
  console.log(data['user']);
  if (data['user'] === 'true') {
    this.router.navigateByUrl('home', { skipLocationChange: true });
    // this.router.navigate(['home', { skipLocationChange: true }]);
    this.authState = true;
  } else { this.store.clear(); this.router.navigate(['login']); }
  return  data['user'];
});
// return token;
}
async leer_token(nom) {
  await this.store.get(nom).then(
    data => { this.ustok[nom] = data; return this.ustok[nom];},
    error => {console.error(error); this.authState = false; return; });
}
async leer_clave(nom) {
  const ltodo = await this.store.get(nom);
  return ltodo;
  }
async leer_todo() {
await this.store.forEach((key, value, index) => {
this.ltodo[value] = key;
  });
//const ltodo = await this.store.keys();
return this.ltodo;
}
async removedata(){
  await this.store.remove('gradatok');
  await this.store.remove('gradaid' );
  await this.store.remove('gradarol');
  await this.store.remove('gradanom');
  await this.store.remove('gradamail');
  await this.store.remove('gradauapp');
  await this.store.remove('gradasusc');
  await this.store.remove('gradaprem');
}
async set_token(id, tok, rol, nom, mail, susc, versio, premium) {
await this.store.set('gradatok' , tok);
await this.store.set('gradaid' , id);
await this.store.set('gradarol' , rol);
await this.store.set('gradanom' , nom);
await this.store.set('gradamail' , mail);
await this.store.set('gradauapp' , versio);
await this.store.set('gradaprem' , premium);
await this.storage2?.set('gradasusc' , susc);
this.authState = true;
}
async set_app(appver, userver) {
  await this.store.set('gradaapp' , appver);
  await this.store.set('gradauapp' , userver);
  this.authState = true;
}
altalogin(postdada) {
//const urlget = `${this.pathleer2}?accio=leerjugador`;
return this.http.post(this.path2, postdada);
}
altatok(postdada) {
// this.postData = postdada;
return this.http.post(this.path2, postdada);
}
isAuthenticated() {
 return this.authState;
}
altauser(formDades) {
  this.postData=(formDades);
  //console.log((this.postData));
  return this.http.post(this.path2, this.postData);
  }
loginuser(formDades) {
  this.postData=(formDades);
  //console.log((this.postData));
  return this.http.post(this.path2, this.postData, this.Options3);
}
loginautomatic(formDades) {
  //this.postData= {...formDades};
  this.postData=(formDades);
  //console.log((this.postData));
  return this.http.post(this.path2, this.postData, this.Options3);
}
}
